import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { BackButtonHeader } from "./back-button";
import { store } from "../../components/Store";
import { useDefaultLangByCountry } from "../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../hooks/use-translations";
import { LANGUAGE_RULE_COUNTRIES } from "../../config/constants";

/**
 * Este componente muestra la pantalla de encontre mi institución pero no puedo ingresar - Información de contacto
 * Su principal función es mostrar lo datos de nombre y correo de la institución y un botón que redirige a un tutorial de como ingresar
 * Los datos de la institución son recibidos a través del estado de la ruta (hook de useHistory, history.push({pathname:'...', state:...}))
 * Estado que se debe enviar: {name:string, email:string, linkTutorial:string}
 */

export const ContactInfoInstitution = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textos = useTranslations({ section: "autenticacion_institucional", lang: lang });

	const location = useLocation();
	const history = useHistory();
	const storeContext = useContext(store);
	const appState = storeContext?.state;
	const isSelectedLibrary = appState?.institutionalAuth?.isSelectedLibrary;

	const data = {
		instName: location?.state?.name || "N/A",
		instEmail: location?.state?.email || "N/A",
		instLinkTutorial: location?.state?.linkTutorial || "N/A",
	};

	const isEmailOrUrl = () => {
		const emailRegex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (emailRegex.test(data?.instEmail)) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		if (!!location?.state === false) {
			history.replace("/");
		}
	}, [location?.state]);

	useEffect(() => {
		if (isSelectedLibrary !== true) {
			history.replace("/institutional-auth/search");
		}
	}, [isSelectedLibrary]);

	return (
		<div className="institutional-auth">
			<div className="bg-shape"></div>
			<div className="row justify-content-center">
				<div className="container page-content">
					<BackButtonHeader />
					<div className="mt-5 row justify-content-center">
						<h1 className="col-12 col-md-8 mt-5 mt-lg-2 text-center onsecondary-title">
							{textos?.no_puedo_ingresar?.info?.titulo || "Contacta a"}
						</h1>
					</div>

					<div className="row justify-content-center">
						<div
							className="col-10 col-md-6 row p-3 rounded onsecondary--bordered onsecondary--bg m-2"
							style={{ borderRadius: ".5rem" }}
						>
							<div
								className="col-12 mb-2  secondary--borderb-color"
								style={{ borderBottom: "solid 1.5px" }}
							>
								<p className="font-weight-bold mb-2 secondary--color">
									{textos?.no_puedo_ingresar?.info?.nombre_instituto ||
										"Nombre del instituto"}
								</p>
								<p className="mb-4 secondary--color">{data?.instName}</p>
							</div>
							<div
								className="col-12 mb-2 secondary--borderb-color"
								style={{ borderBottom: "solid 1.5px" }}
							>
								<p className="font-weight-bold fw-bold mb-2 secondary--color">
									{textos?.no_puedo_ingresar?.info?.contacto || "Contacto"}
								</p>
								<div className="mb-4">
									<a
										className="w-100 secondary-link font-weight-light focusable-primary break-word text-break"
										href={`${
											isEmailOrUrl()
												? `mailto:${data?.instEmail}`
												: data?.instEmail
										}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{data?.instEmail}
									</a>
								</div>
							</div>
							{data?.instLinkTutorial &&
								data?.instLinkTutorial?.trim()?.length > 0 && (
									<a
										className="col-12 my-3 secondary-button focusable-primary"
										href={`${data?.instLinkTutorial}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{textos?.no_puedo_ingresar?.info?.tutorial || "Tutorial"}
									</a>
								)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
