export const COLEGIOS_DATA = [
  {
    paquete: [
      'alianza.png', 
      'Nogales.png', 
      'Campestre.png', 
      'Madison.png', 
      'Mercy.png', 
      'Columbus.png', 
      'Corazonista.png',
      'NuevaGranada.png'
    ]
  },
  {
    paquete: [
      'Jefferson.png', 
      'Marymount.png', 
      'port-chester.png', 
      'waukesha.png',
      'Breteau.png',
      'colegio-anglo-colombiano.png',
      'colegio-canapro.png',
      'colegio-tomas-moro.png',
    ]
  },
  {
    paquete: [
      'deutsche.png',
      'gimnasio-la-montaña.png',
      'Moderno.png',
      'gimnasio-vermont.png',
      'instituto-lux.png',
      'chicago-public-schools.png',
      'ossining-school-district.png',
      'pflugerville-district.png'
    ]
  },
  {
    paquete: [
      'colegio-seminario-duitama.png',
      'milton-school-district.png'
    ]
  }
]