import React from "react";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Keyboard, Navigation, Pagination } from "swiper";
import { Img } from "../../../components/shared/img";
import { COLEGIOS_DATA } from "../data/colegios-data";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente de "Experiencia comprobada" para que es bibliotecas
 * @returns {JSX.Element} Componente Experience.
 */
export const Experience = () => {
	const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
	const textoColegios = useTranslations({ section: "que_es_colegios", lang: lang });

	/**
	 * Esta función crea un arreglo con varios SwiperSlide. Cada uno de estos contiene un comentario
	 * @returns {Array<HTMLElement>} arreglo de SwiperSlide
	 */
	const crearSlide = () => {
		let items = [];
		const comentarios = textoColegios?.comentarios || [];
		Object.keys(comentarios).map((item, index) => {
			if (comentarios[item].comentario !== "null") {
				items.push(
					<SwiperSlide
						className={`swiper-slide comments`}
						key={`slide-${index}`}
						style={{ fontSize: "0.9em" }}
					>
						<div
							tabIndex={0}
							className="focusable-primary experience-schools mx-auto py-4 mb-5 mt-3"
						>
							<p className="d-block col-11 mx-auto text-center ">
								{" "}
								{comentarios[item].comentario}{" "}
							</p>
						</div>
						<p
							tabIndex={0}
							className="focusable-primary col-lg-10 mx-auto text-center onbackground-primary--color"
						>
							<span className="font-weight-bold">{comentarios[item].nombre} </span>{" "}
							{comentarios[item].cargo !== "null" && comentarios[item].cargo}{" "}
						</p>
						<p
							tabIndex={0}
							className="focusable-primary col-lg-10 mx-auto text-center font-weight-bold onbackground-primary--color"
						>
							{" "}
							{comentarios[item].lugar}{" "}
						</p>
					</SwiperSlide>
				);
			}
		});
		return items;
	};

	/**
	 * Esta función crea los enlaces de las colegios
	 * @param {object} slide
	 * @returns {Array<HTMLElement>} arreglo de imagenes
	 */
	const crearColegios = (slide) => {
		let items = [];
		for (let i = 0; i < slide.paquete.length; i++) {
			const nombreColegio = slide?.paquete?.[i].replace(".png", "").replace(".jpg", "");
			items.push(
				<div
					className="col-4 col-lg-3 p-1 p-lg-2 focusable-by-children-secondary"
					key={`colegio-${i}`}
				>
					<button aria-label={`colegio ${nombreColegio}`}>
						<div className="cover">
							<Img
								src={`/img/instituciones/${slide.paquete[i]}`}
								alt={`colegio-${i}`}
								className="img-fluid"
							/>
						</div>
					</button>
				</div>
			);
		}
		return items;
	};

	/**
	 * Esta función crea un SwiperSlide con un conjunto de Colegios
	 * @returns {Array<HTMLElement>} arreglo de un conjunto de Colegios
	 */
	const crearSlideColegios = () => {
		let items = [];
		for (let i = 0; i < COLEGIOS_DATA.length; i++) {
			items.push(
				<SwiperSlide key={`slideColegios-${i}`}>
					<div className="row mx-0">{crearColegios(COLEGIOS_DATA[i])}</div>
				</SwiperSlide>
			);
		}
		return items;
	};

	return (
		<>
			<div className="experience  pb-4">
				<div className="container px-lg-0">
					<div className="col-lg-10 mx-auto">
						<h1
							tabIndex={0}
							className="focusable-secondary secondary-font font-weight-bold py-4 onbackground-primary--color "
						>
							{textoColegios?.experiencia?.titulo || "Experiencia comprobada"}
						</h1>
						<p className="onbackground-primary--color" style={{ fontSize: "0.9em" }}>
							{textoColegios?.experiencia?.descripcion || "descripcion"}
						</p>

						<Swiper
							autoplay={{
								delay: 1000,
							}}
							breakpoints={{
								526: {
									slidesPerView: 1,
									slidesPerGroup: 1,
								},
								992: {
									slidesPerView: 1,
									slidesPerGroup: 1,
								},
								1400: {
									slidesPerView: 1,
									slidesPerGroup: 1,
								},
							}}
							className="py-lg-3 mb-4 swiper-editoriales"
							loop={true}
							modules={[Autoplay, Pagination]}
							pagination={{
								clickable: true,
								type: "bullets",
							}}
							preloadImages={true}
							slidesPerView={1}
							style={{ height: 400 }}
						>
							{crearSlide()}
						</Swiper>
					</div>
				</div>
			</div>

			<div className="container px-lg-0 py-5">
				<div className="col-lg-10 mx-auto">
					<h2 className="focusable-secondary secondary-font font-weight-bold pb-4 onbackground-primary--color">
						{textoColegios?.experiencia?.subtitulo ||
							"¡Los mejores colegios ya están con makemake!"}
					</h2>

					<Swiper
						className="swiper-container pt-lg-3  pb-lg-5 swiper-editoriales"
						loop={true}
						preloadImages={true}
						slidesPerView={1}
						autoplay={{
							delay: 5000,
						}}
						keyboard={{
							enabled: true,
						}}
						modules={[Autoplay, Keyboard]}
						breakpoints={{
							526: {
								slidesPerView: 1,
								slidesPerGroup: 1,
							},
							992: {
								slidesPerView: 1,
								slidesPerGroup: 1,
							},
							1400: {
								slidesPerView: 1,
								slidesPerGroup: 1,
							},
						}}
						pagination={{
							clickable: true,
							type: "bullets",
						}}
					>
						{crearSlideColegios()}
					</Swiper>
				</div>
			</div>
		</>
	);
};
