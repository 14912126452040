import React, { useRef, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "@iconify/react";
import { store } from "../Store";

import { useAdvancedSearch } from "../../hooks/advanced-search/use-advanced-search";
import { useAdvancedSearchContentData } from "../../hooks/advanced-search/use-advanced-search-content-data";
import useObtenerSubsecciones from "../../js/translations/useObtenerSubsecciones";
import { useUserBooks } from "../../hooks/useUserBooks";
import useIsMobile from "../../js/utilities/useIsMobile";

import { Header } from "./header";
import { Footer } from "./footer";
import { AdvancedSearchItems, THEME_CARD_COLORS } from "./item-cards";

import { IconBlog } from "../header/public-header/components/icons/IconBlog";
import { IconComunidad } from "../header/public-header/components/icons/IconComunidad";

import _ from "lodash";

/**
 * Componente principal del menú de búsqueda avanzada,
 * se encarga de renderizar el Header, Footer, y el contenido donde se muestran todos los items (generos, materias, niveles de lecturas, tags, entre otros.)
 * @returns {React.Component}
 */
export const AdvancedSearch = () => {
    const { textosSubSecciones: textos } = useObtenerSubsecciones('busqueda_avanzada');
    const { textosSubSecciones: textosHeader } = useObtenerSubsecciones('header');
    const advancedSearch = useAdvancedSearch();
    const visible = advancedSearch?.state?.visible;
    const data = useAdvancedSearchContentData();
    const history = useHistory();
    const ref = useRef(null);
    const globalState = useContext(store);
    const triggerButton = globalState?.state?.advancedSearch?.triggerButton;
    const userBooksData = useUserBooks();
    const isMobile = useIsMobile();


    const hide = () => {
        advancedSearch.setVisible(false);
    }
    useEffect(() => {
        if (visible) {
            document.getElementsByTagName('html')[0].style.overflowY = "hidden";
        } else {
            document.getElementsByTagName('html')[0].style.overflowY = "auto";
        }
    }, [visible])

    const handleOutsideClick = (event) => {
        if (triggerButton?.current?.contains(event.target)) return;
        if (ref.current && !ref.current.contains(event.target)) {
            advancedSearch.setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [triggerButton]);


    return (<div ref={ref} onClick={(e) => e.target === e.currentTarget && hide()} className={`advanced-search ${visible ? 'visible' : 'hidden'}`}>
        {advancedSearch?.state?.loadingContent && userBooksData.loading ?
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Icon icon="icomoon-free:spinner9" width={60} className={'spinner-rotation secondary-inverse--color'} />
            </div>
            :
            <section className="menu container">
                <Header />
                <section className="content p-3 px-4">
                    <div className="row p-0 m-0 main-section">

                        <section className="col-12 col-lg-5 col-xl-4 mx-0">
                            <div className="d-flex align-items-center">
                                <p className="section-title col-6 col-sm-4 col-md-3 col-lg-12 p-0 py-2">{_.get(textos, `content.seccion.titulo.mas_buscado`) || 'Lo más buscado'}</p>
                                <hr className="w-100 d-lg-none onsecondary--color" />
                            </div>

                            {
                                isMobile ?
                                    <section className="d-flex flex-wrap justify-content-lg-between py-4  px-0 line-bottom">
                                        {data?.mostSearchedMobile?.filter(item => item?.type === 'genero' || item?.type === 'tema').map((genre, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className="h-fit col-4 col-sm-3 col-lg-2 mb-4"
                                                >
                                                    <AdvancedSearchItems.DefaultItemCard
                                                        key={i}
                                                        imgPath={genre?.imgPath}
                                                        name={_.get(textos, genre?.name?.translationPath) || genre?.name?.defaultValue}
                                                        onClick={() => {
                                                            history.push({ pathname: '/busqueda', search: `?${genre?.type}=${genre?.id}` })
                                                            advancedSearch.setVisible(false);
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </section>
                                    :
                                    <section className="d-flex flex-wrap justify-content-lg-between py-4  px-0 line-bottom">
                                        {data?.mostSearched?.filter(item => item?.type === 'genero' || item?.type === 'tema').map((genre, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className="h-fit col-4 col-sm-2 col-lg-2"
                                                >
                                                    <AdvancedSearchItems.DefaultItemCard
                                                        key={i}
                                                        imgPath={genre?.imgPath}
                                                        name={_.get(textos, genre?.name?.translationPath) || genre?.name?.defaultValue}
                                                        onClick={() => {
                                                            history.push({ pathname: '/busqueda', search: `?${genre?.type}=${genre?.id}` })
                                                            advancedSearch.setVisible(false);
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </section>

                            }




                            {isMobile &&
                                <div className="d-flex align-items-center">
                                    <p className="section-title col-6 col-sm-4 col-md-3 col-lg-12 p-0 py-2">{'Niveles de lectura'}</p>
                                    <hr className="w-100 d-lg-none onsecondary--color" />
                                </div>
                            }

                            <section className="d-flex flex-wrap py-4 line-bottom">
                                {data?.readingLevel?.map((nivel, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="mb-2 col-12 px-0">
                                            <AdvancedSearchItems.ReadingLevelCard
                                                key={i}
                                                imgPath={nivel?.imgPath}
                                                name={_.get(textos, nivel?.name?.translationPath) || nivel?.name?.defaultValue}
                                                onClick={() => {
                                                    history.push({ pathname: '/busqueda', search: `?${nivel?.type}=${nivel?.id}` })
                                                    advancedSearch.setVisible(false);
                                                }} />
                                        </div>
                                    )
                                })}
                            </section>

                            {!isMobile &&
                                <section className="py-4">
                                    <div className="mb-3 ">
                                        <AdvancedSearchItems.CollectionCard
                                            backgroundColor={'#FFD103'}
                                            color={'#000'}
                                            name={_.get(textos, 'content.seccion.curadurias.colecciones') || 'Colecciones'}
                                            onClick={() => {
                                                history.push('/curadurias/colecciones');
                                                hide();
                                            }} />
                                    </div>
                                    <div className="mb-3">
                                        <AdvancedSearchItems.CollectionCard
                                            backgroundColor={'#4A90E2'}
                                            color={'#fff'}
                                            name={_.get(textos, 'content.seccion.curadurias.series') || 'Series'}
                                            onClick={() => {
                                                history.push('/curadurias/series');
                                                hide();
                                            }} />
                                    </div>
                                    <div className="mb-3">
                                        <AdvancedSearchItems.CollectionCard
                                            backgroundColor={'#EA5A18'}
                                            color={'#fff'}
                                            name={_.get(textos, 'content.seccion.curadurias.paises') || 'Países'}
                                            onClick={() => {
                                                history.push('/curadurias/paises');
                                                hide();
                                            }} />
                                    </div>
                                </section>
                            }
                        </section>

                        {isMobile &&
                            <div className="w-100 option-responsive mb-5">
                                <hr className="w-100 d-lg-none onsecondary--color" />
                                <ul className="mt-3">
                                    <li>
                                        <button
                                            onClick={() => {
                                                history.push({ pathname: '/club-makemake' });
                                                advancedSearch.setVisible(false);
                                            }}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <IconComunidad color={'var(--secondary-inverse)'} className="mx-2" />
                                            {textosHeader?.comunidad_makemake || 'Comunidad MakeMake'}
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            onClick={() => {
                                                history.push({ pathname: '/blog' });
                                                advancedSearch.setVisible(false);
                                            }}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <IconBlog color={'var(--secondary-inverse)'} className="mx-2" />
                                            {textosHeader?.blog || 'Blog'}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        }


                        {!isMobile &&
                            <div className="col-lg-7 col-xl-8">
                                <p className="section-title col-12 p-0 py-2">{_.get(textos, `content.seccion.titulo.temas`) || 'Temas'}</p>
                                <section className="row p-0 m-0 testing-border col-12">
                                    <div className="testing-border col-12 p-0 m-0">
                                        <div className="d-flex flex-wrap justify-content-md-between">
                                            {
                                                data?.tags1?.map((theme, i) => {
                                                    return <div key={i} className="">
                                                        <AdvancedSearchItems.ThemeCard key={i}
                                                            name={theme}
                                                            hexColor={THEME_CARD_COLORS[i]}
                                                            shapeNumber={i + 1}
                                                            onClick={() => {
                                                                history.push({ pathname: '/busqueda', search: `?sch=${theme}` })
                                                                advancedSearch.setVisible(false);
                                                            }} />
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="testing-border col-12 p-0 m-0 py-3">
                                        <div className="d-flex flex-wrap">
                                            {
                                                data?.tags2?.map((cloud, i) => {
                                                    return <AdvancedSearchItems.CloudTag key={i}
                                                        name={cloud} onClick={() => {
                                                            history.push({ pathname: '/busqueda', search: `?sch=${cloud}` })
                                                            advancedSearch.setVisible(false);
                                                        }} />
                                                })
                                            }
                                        </div>
                                    </div>
                                </section>

                                <section className="row p-0 m-0 testing-border col-12">
                                    <section className="row  p-0 m-0 testing-border col-12 col-sm-6">
                                        <div className="row">
                                            {data?.genres?.map((genre, i) => {
                                                return <div key={i} className="h-fit col-4 col-sm-6 col-md-3 p-3">
                                                    <AdvancedSearchItems.DefaultItemCard
                                                        key={i}
                                                        imgPath={genre?.imgPath}
                                                        name={_.get(textos, genre?.name?.translationPath) || genre?.name?.defaultValue}
                                                        onClick={() => {
                                                            history.push({ pathname: '/busqueda', search: `?${genre?.type}=${genre?.id}` })
                                                            advancedSearch.setVisible(false);
                                                        }} />
                                                </div>
                                            })}
                                        </div>
                                    </section>
                                    <section className="row pl-3 pl-0 pl-md-3  p-0 m-0 testing-border col-12 col-sm-6">
                                        <div className="row p-0 m-0">
                                            {data?.subjects?.map((subject, i) => {
                                                return <div
                                                    key={i}
                                                    className="col-12 col-md-6 p-1">
                                                    <AdvancedSearchItems.SubjectCard
                                                        key={i}
                                                        imgPath={subject?.imgPath}
                                                        name={_.get(textos, subject?.name?.translationPath) || subject?.name?.defaultValue}
                                                        onClick={() => {
                                                            history.push({ pathname: '/busqueda', search: `?${subject?.type}=${subject?.id}` })
                                                            advancedSearch.setVisible(false);
                                                        }} />
                                                </div>
                                            })}
                                        </div>
                                    </section>
                                </section>
                            </div>
                        }
                    </div>
                </section>
                {!isMobile && <Footer />}
            </section>
        }
    </div >)
}


