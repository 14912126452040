import React, { useContext, useState, useEffect, useRef } from "react";
import { store } from "../../Store";

import { NavbarBrand } from "../components/NavbarBrand";
import { NavbarNav } from "./components/NavbarNav";
import { DropdownResponsive } from "./components/DropdownResponsive";
import { SkeletonHeader } from "./components/SkeletonHeader";

import useIsMobile from "../../../js/utilities/useIsMobile";
import { useConexion } from "../../../hooks/useConexion";
import { useDefaultLangByCountry } from "../../../hooks/use-default-lang-by-country";
import { useTranslations } from "../../../hooks/use-translations";
import { usePublicHeaderDropdown } from "../../../hooks/header/usePublicHeaderDropdown";
import { LANGUAGE_RULE_COUNTRIES } from "../../../config/constants";

/**
 * Componente del header sin login
 * @returns {JSX.Element} - PublicHeader
 */
export const PublicHeader = () => {
  const lang = useDefaultLangByCountry({ country: LANGUAGE_RULE_COUNTRIES, defaultLang:'en' });
  const textosInterfaz = useTranslations({ section: "header", lang: lang, array: true });

  const globalState = useContext(store);
  const { dispatch, state } = globalState;

  const [isLoading, setIsLoading] = useState(true);

  const isMobile = useIsMobile();
  const internetConnection = useConexion();

  const publicHeaderDropdown = usePublicHeaderDropdown();
  const dropdownButtonRef = useRef(null);

  /*Asigna el botón que abre el dropdown del header publico o sin login */
  useEffect(() => {
    if (dropdownButtonRef.current) {
      globalState.dispatch({
        type: "CHANGE",
        path: "publicHeaderDropdown.triggerButtonRef",
        value: dropdownButtonRef,
      });
    }
  }, [dropdownButtonRef.current]);


  useEffect(() => {
    if (state?.datosHeader) {
      setIsLoading(false);
    }
  }, [state?.datosHeader]);


  return (
    <>
      {isLoading ?
        <SkeletonHeader />
        :
        <div className="public-header">
          <nav className="h-100 row mx-0">
            <div className="col-6 col-lg-2 col-xl-4 px-0 d-flex">
              <NavbarBrand />
            </div>

            {!isMobile &&
              <div className="col-lg-10 col-xl-8">
                <NavbarNav textosInterfaz={textosInterfaz} lang={lang}/>
              </div>
            }

            {isMobile &&
              <div className="col-6 px-0  d-flex justify-content-end align-items-center">
                <button
                  ref={dropdownButtonRef}
                  onClick={() => {
                    if (internetConnection.online) {
                      publicHeaderDropdown.setVisible(!publicHeaderDropdown?.state?.visible)
                    }
                  }}
                  className={`btn-menu focusable-red ${publicHeaderDropdown?.state?.visible && 'activeMode'}`}
                >
                  {textosInterfaz?.menu || 'Menú'}
                  <i className="fas fa-bars ml-3"></i>
                </button>
              </div>
            }
          </nav>

          {
            isMobile &&
            <DropdownResponsive textosInterfaz={textosInterfaz} />
          }


        </div>
      }
    </>
  )
}

